<template>
  <v-autocomplete
    v-model="model"
    :label="autocompleteType"
    :items="searchResults"
    :loading="loading"
    :search-input.sync="location"
    item-text="description"
    outlined
    hide-no-data
    hide-selected
    return-object
    dense
    @change="pushPlaceMarker"
  ></v-autocomplete>
</template>

<script>
export default {

  props: {
    returnLatLng: {
      type: Function,
      required: true,
    },

    autocompleteType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      service: null,
      geocoder: null,

      // Autocomplete form
      model: null,
      searchResults: [],
      loading: false,
      location: null
    }
  },

  watch: {
    location(val) {
      if(val) {
        this.service.getPlacePredictions(
          {
            input: this.location,
            componentRestrictions: { country: 'sn' },
            types: ['establishment']
          },
          this.displaySuggestions
        )
      }
    }
  },

  mounted() {
    this.service = new google.maps.places.AutocompleteService()
    this.geocoder = new google.maps.Geocoder()
  },

  methods: {

    pushPlaceMarker() {
      this.geocoder.geocode({ placeId: this.model.place_id }).then(({results}) => {

        let lat = results[0].geometry.location.lat()
        let lng = results[0].geometry.location.lng()
        const payload = {
          name: this.model.description,
          lat: lat,
          lng: lng
        }
        this.returnLatLng(this.autocompleteType, payload)

      })
    },


    displaySuggestions(predictions, status) {
      if(status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }

      this.searchResults = predictions.map(
        (prediction) => prediction
      )
    }
  }

}
</script>

<style>

</style>